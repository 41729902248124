import React from "react";
import Page from "../../components/Page";
import OHIFGalleryHeader from "../../components/OHIFGalleryHeader";
import SEO from "../../components/SEO";

function BehindTheScene() {
  return (
    <Page>
      <SEO title="Modes | OHIF" />
      <main className="mt-16">
        <div>
          <div>
            <OHIFGalleryHeader />
          </div>
          <div className="flex flex-col px-12 py-16 space-y-8 text-white">
            <h2 className="text-5xl text-blue-100">How to Install Modes?</h2>
            <p>
              OHIF-v3 provides the ability to utilize external modes and
              extensions. You can follow the instructions in our documentation
              page to install external modes.
            </p>
            <p>
              OHIF-cli is a command line tool that can be used to install
              external modes. Each mode page has a installation instruction in
              the header when you click on the install icon. Read more about
              ohif-cli in the documentation page
              <a
                href="https://docs.ohif.org/development/ohif-cli/#cli-installation"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-100"
              >
                {" "}
                here
              </a>
            </p>
          </div>
        </div>
      </main>
    </Page>
  );
}

export default BehindTheScene;
